import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Face from "./Face";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(
    Array(product.weight.length).fill(1)
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const { addToCart } = useContext(CartContext);

  const handleAddToCart = (weight, price, index) => {
    addToCart(product.id, weight, quantities[index], price);
    navigate("/cartpage");
  };

  const handleQuantityChange = (index, delta) => {
    setQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = Math.max(1, newQuantities[index] + delta);
      return newQuantities;
    });
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
            <div className="goBackToShop">
              <Link to="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</Link>
            </div>
          </div>
          <div className="product-info">
            <p className="product-brand">
              <Link to="https://www.benjaminbarker.co/collections/vendors?q=Benjamin%20Barker">
                {product.brand}
              </Link>
            </p>
            <h1>{product.name}</h1>
            <h2 className="startPrice">From {product.price[0]}</h2>

            {product.weight.map((weight, index) => (
              <div className="price-section" key={index}>
                <div className="weightDiv">
                  <h3>
                    {weight}
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </h3>
                  <h2 className="product-price">{product.price[index]}</h2>
                </div>
                <div className="allPriceDiv">
                  <span className="product-save">Repeat & Save</span>
                </div>
                <div className="product-quantity">
                  <p>10+ In stock</p>
                  <div className="quantity-right">
                    <div className="takeQuantity">
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityChange(index, -1)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      <input
                        type="number"
                        value={quantities[index]}
                        onChange={(e) =>
                          setQuantities((prevQuantities) => {
                            const newQuantities = [...prevQuantities];
                            newQuantities[index] = Math.max(
                              1,
                              parseInt(e.target.value)
                            );
                            return newQuantities;
                          })
                        }
                        className="quantity-input"
                      />
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityChange(index, 1)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                    <div className="basketDiv">
                      <button
                        className="add-to-cart"
                        onClick={() =>
                          handleAddToCart(weight, product.price[index], index)
                        }
                      >
                        Add to Basket
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Face />
      <Footer />
    </div>
  );
};

export default ProductDetail;

import "../css/main.css";
import { Link } from "react-router-dom";
function Face() {
  return (
    <div className="follow-container">
      <div className="faceIcons">
        <h2>Follow us on</h2>
        <Link to="https://www.facebook.com/petplanetuk">
          <img src="/faceIcon/face.png" alt="Facebook" />
        </Link>
        <Link to="https://www.instagram.com/petplanet.co.uk">
          <img src="/faceIcon/insta.png" alt="Instagram" />
        </Link>
        <Link to="https://twitter.com/PetPlanetcouk">
          <img src="/faceIcon/Twir.png" alt="Twitter" />
        </Link>
        <Link to="https://www.youtube.com/PetPlanetcouk">
          <img src="/faceIcon/Ytub.png" alt="YouTube" />
        </Link>
      </div>
    </div>
  );
}
export default Face;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";
import Face from "./Face";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileScreenButton,
  faPhone,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

function ContactUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactDiv">
        <div className="h2cc">
          <h2>CONTACT US</h2>
        </div>

        <div className="contactForm">
          <div className="contact-content1">
            <FontAwesomeIcon icon={faEnvelope} />
            <strong>By Email</strong>
            <p>
              If you'd like to ask the Pet Planet team a question about a
              product or an order or you simply want to tell us about your Pet
              Planet experience, you can get in touch by email or phone.
            </p>
            <p>To get in touch by email please fill in the form below.</p>
          </div>
          <div className="contact-content">
            <strong className="inputLable">Your Email</strong>
            <input type="text" />
          </div>
          <div className="contact-content">
            <strong className="inputLable">Order Number</strong>
            <input type="text" />
          </div>
          <div className="contact-content">
            <strong className="inputLable">Subject</strong>
            <select name="subject" id="subject" class="subject-select">
              <option value="">Please Select...</option>
              <option value="enquiry">Enquiry</option>
              <option value="complaint">Complaint</option>
              <option value="suggestion">Suggestion</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="contact-content">
            <strong className="inputLable">Enquiry</strong>
            <textarea />
          </div>

          <div className="btnDiv">
            <button>Contact Us</button>
          </div>
          <div className="link-content">
            <FontAwesomeIcon icon={faMobileScreenButton} />
            <strong>By Phone</strong>
            <p>
              Rather speak to us on the phone? Give us a call and we'll be happy
              to help.
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} />
              01506 605150
            </p>
            <p>
              <FontAwesomeIcon icon={faClockRotateLeft} />
              Monday - Friday: 9am - 5pm
            </p>
          </div>
        </div>
      </div>
      <Face />
      <Footer />
    </div>
  );
}

export default ContactUs;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Face from "./Face";
import "../css/bottom.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactDiv">
        <div className="h2cc">
          <h2>PRIVACY POLICY</h2>
        </div>

        <div className="privacyMid">
          <p>
            Petplanet.co.uk is committed to protecting your privacy. We are
            registered as a Tier 1 Data Controller with the Information
            Commissioner's office
            (https://ico.org.uk/ESDWebPages/Entry/Z9816782) - we will never sell
            or rent your personal information for marketing purposes.
          </p>
          <p>
            Petplanet.co.uk is a wholly owned subsidiary of M8 Group Ltd. If you
            have indicated that you are happy to receive email notifications of
            offers from Petplanet.co.uk and other M8 Group Companies, then your
            email address will be shared with those M8 Group companies. You can
            update these preferences from your "my account" at any time.
          </p>
          <p>
            In the event that Petplanet.co.uk Ltd or M8 Group Ltd are subject to
            change of ownership, the control and permissions that you have
            granted to us with regard to your personal data will be transferred
            to the new owner.
          </p>
          <strong>What information do we collect and why?</strong>
          <p>
            We use various pieces of information to help us improve your
            experience in different ways, but we won’t ask you for information
            that we don’t need.
          </p>
          <p>&nbsp;</p>

          <h3>Contact Details</h3>
          <p>Name</p>
          <p>Billing Address</p>
          <p>
            This is essential to allow us to complete our contract with you.
            Also, we use this to help detect and prevent fraud against you and
            us. Delivery Address
          </p>
          <p>
            We simply need to know where you want us to send the order if it’s
            not going to your billing address. Phone Number & Mobile Number
          </p>
          <p>
            We keep in touch with our customers if we have a query with your
            order and the best way to do this is to give you a call. We use your
            mobile number to improve your experience, we know that everyone is
            busy and want a simple convenient delivery so we use a variety of
            couriers to deliver our orders most of whom will send SMS messages
            to you to let you know when they are delivering the parcel and some
            even give you a 1 hour delivery window as well as the option to
            divert the parcel ‘in flight’ all of which we know will improve your
            experience.
          </p>
          <p>Email address</p>

          <p>&nbsp;</p>
          <p>This is essential to have an account with us.</p>
          <p>
            We have a contractual requirement to send you information about your
            order such as confirmation of your order and when we have despatched
            it so it is essential we send you these emails. Our couriers will
            also use this to keep in touch with you about your delivery and in
            most cases provide you with options to amend your delivery to a time
            that is more convenient to you. We also ask our customers if they
            wish to receive exclusive offers, discount vouchers, and new product
            information and we use emails to keep our customer up to date with
            these. For those customers who don’t want to receive these you can
            change your preferences in your ‘My Account’ section or unsubscribe
            via any email we have sent to you.
          </p>
          <h3>Payment Details</h3>
          <p>
            Debit/Credit Card details including Security/CVV code or your Paypal
            information.
          </p>
          <p>&nbsp;</p>
          <p>Firstly, rest assured we do not keep this information.</p>
          <p>We need the information to complete the contract of purchase.</p>
          <p>
            We also need this information to help detect and prevent fraud
            against you and us.
          </p>
          <p>
            We use this information to be able to carry out any refunds to you.
          </p>
          <p>
            We use highly reputable companies to process all our payments. For
            card payments we use Braintree and we also use Paypal.
          </p>
          <p>&nbsp;</p>
          <h3>Communication Details</h3>

          <p>Via Phone / Email / Contact Form / Social Media</p>
          <p>
            While we are continually striving to improve our businesses and find
            better ways to ensure we provide the customer with what they want
            when they want it, we acknowledge from time to time things can go
            wrong. On these occasions it is essential that we keep a record of
            your communication with us and ours with you so we can deliver the
            best service to you and also so we can not only improve our customer
            care via training but also where necessary improve our products to
            better meet our customers’ needs.
          </p>
          <p>&nbsp;</p>
          <h3>Purchase History Details</h3>

          <p>
            It is essential that if you contact us we can find your order
            history on our system so we are able to discuss things with you and
            resolve any queries quickly.
          </p>
          <p>
            If a manufacturer ever had a product recall we need to be able to
            find and contact all customers who purchased the product as quickly
            as possible.
          </p>
          <p>
            From time to time manufacturers change a product and if you buy this
            product reguarily we will send you an email to keep you updated of
            the changes to make your shopping experience easier with us.
          </p>
          <p>
            We also use this information to keep a track of the balance of your
            Loyalty Points so you can use this to receive free of charge
            products or as a discount on future orders.
          </p>
          <p>&nbsp;</p>

          <h3>Favourite Product Details</h3>
          <p>
            We keep this information to help you navigate our shop quicker and
            you are fully in control of these.
          </p>
          <p>&nbsp;</p>
          <h3>Product & Service Reviews</h3>
          <p>
            We ask you to give us a review of products you have purchased or the
            service you have received so we can do things better and make sure
            we are delivering what you expect. We will publish some of these
            reviews on our websites but we will always do so in a manner that
            means anyone looking at our site can’t identify you.
          </p>
          <p>&nbsp;</p>
          <h3>Competitions or Prize Draws</h3>

          <p>
            When you enter one of our competitions or prize draws either via our
            website or social media it is essential we hold this information to
            be able to administer the competition or draw and notify the winner.
            We may also use this information to directly market you about
            products.
          </p>
          <p>&nbsp;</p>
          <h3>Sharing your information</h3>
          <p>
            As we said at the start we will never sell or rent your information
            to a third party. We do however, have a requirement as explained
            above to provide some of your information to a third party in order
            to complete your transaction with us. This includes providing
            information to complete the financial part of the transaction with
            our payment services providers and also providing the delivery
            companies with the necessary information to be able to deliver the
            order to you as easily as possible. On each occasion we only provide
            the information that is necessary.
          </p>
          <p>&nbsp;</p>

          <h3>Your rights</h3>
          <p>
            You have the right to be told how we use and keep your personal
            information and we do this via this policy and our terms and
            conditions.
          </p>
          <p>
            You have the right to ask for a copy of the information we hold on
            you.
          </p>
          <p>
            You have the right to have any inaccuracies corrected and you can
            either contact us or do this via your ‘My Account’ page.
          </p>
          <p>
            You have the right to ask us not to send you marketing, promotional
            or product emails and you can change your preferences via your ‘My
            Account’ page or you can unsubscribe on any email we have sent you.
          </p>
          <p>
            You have the right to have your details deleted from our system
            however, we must keep information that is essential should you wish
            to contact us in the future about an issue you may have about a
            product.
          </p>
          <p>&nbsp;</p>
          <h3>Changes</h3>
          <p>
            Any changes to our privacy policy will be posted on this page so
            that you are always aware of what information we collect, how we use
            it and under what circumstances we disclose it. If we make any
            significant changes we will contact you directly via email.
          </p>

          <p>&nbsp;</p>

          <h3>Cookies</h3>
          <p>
            We do use 'cookies' which are small pieces of information that are
            stored on your devices which allows you to maintain you connection
            with our website. For more information please see our cookie policy.
          </p>

          <p>&nbsp;</p>

          <h3>In Summary</h3>
          <p style={{ paddingBottom: "10px" }}>
            We are completely committed to protecting your privacy. If you have
            any comments or queries about our privacy policy, please get in
            touch via our ‘Contact Form’ in your ‘My Account’ section or you can
            e-mail us at pet@petplanet.live For further information regarding
            all aspects of PetPlanet.co.uk's contracts with customers please see
            our full Terms and Conditions.
          </p>
        </div>
      </div>
      <Face />
      <Footer />
    </div>
  );
}

export default Privacy;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";
import Face from "./Face";

function AboutUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactDiv">
        <div className="h2cc">
          <h2>About Us</h2>
        </div>

        <div className="refundMid">
          <p>
            Petplanet.co.uk Limited is part of M8 Group Ltd, a privately held
            company based in Livingston, Scotland. Founded in 1999 we have long
            been recognised as the market leader in our sector in the UK.
          </p>
          <p>
            Many Internet companies make unsubstantiated claims to be ‘the
            largest’ or ‘the biggest’ or ‘the best’: these claims are normally
            unjustified. At petplanet.co.uk we are confident in our market
            leading position so we are happy to share some data with you (this
            information is automatically updated every 24 hours to ensure
            accuracy and transparency);
          </p>
          <p>
            Along with sister company www.greenfingers.com, petplanet.co.uk has
            helped make M8 Group Ltd one of the fastest growing private
            companies in the UK. This achievment was recognised by the Sunday
            Times who include us in their Fast Track 100
          </p>
          <p>&nbsp;</p>
          <h3>Prescription Medicines</h3>
          <p>
            When it comes to veterinary products we aim to provide a top quality
            service, and to show our commitment to this, our staff who review
            your veterinary orders are all AMTRA, (Animal Medicines Training &
            Regulatory Authority), qualified Suitably Qualified Persons
            (S.Q.P’s).
          </p>
          <p>
            <strong>Meet our S.Q.P, </strong>(or Animal Health Advisor as we
            like to call them!)
          </p>
          <div className="two">
            <p className="twoP">
              <p>Jacqueline Pullinger</p>
              <p>QC30447</p>
            </p>
            <p className="twoP">
              <p>Emma McNee</p>
              <p>VP1355C</p>
            </p>
            <p className="twoP">
              <p>Aimie Caldwell</p>
              <p>VP1354C</p>
            </p>
          </div>
          <br />
          <p>
            How can you confirm our S.Q.P’s are currently registered with the
            relevant governing body:
          </p>
          <p>
            You can check the Register of SQP’s on the Veterinary Medicines
            Directorate, (V.M.D), website, here:
            http://www.vmd.defra.gov.uk/registers/sqpregister.aspx
          </p>
          <p>
            You can search via the S.Q.P numbers quoted above, the S.Q.P’s name,
            or alternatively you can ring the VMD on 01932 336911 and quote the
            S.Q.P code number to check their status.
          </p>
          <p>
            <strong className="strongLine">Should anything go wrong,</strong>{" "}
            how to contact us to make a complaint:
          </p>
          <p>
            Whilst we aim to offer you impeccable customer service at all times,
            there are occasions where things may go wrong. If you are
            unfortunate enough for this to happen then please contact us
            directly on :-
          </p>
          <p>pet@petplanet.live or call us on 01506 605150</p>
          <p>
            If for any reason our response does not satisfy you then you may
            contact either the:
          </p>
          <p>V.M.D: pet@petplanet.live</p>
          <p>OR</p>
          <p>
            AMTRA: http://www.amtra.org.uk/index.php?page=professional_standards
          </p>
          <p>Email: pet@petplanet.live</p>
          <p>
            <strong className="strongLine">
              Adverse Reactions to a medicinal product
            </strong>
          </p>
          <p>
            On occasion, there can be circumstances where your pet suffers an
            adverse reaction, or a lack of efficacy when you have used a
            product. This should be reported directly to the V.M.D, using the
            details below:
          </p>
          <p>https://www.vmd.defra.gov.uk/adversereactionreporting/</p>
          <p>
            <strong className="strongLine">Can I use this on my pet?</strong>
          </p>
          <p>
            The V.M.D hold a full list of all veterinary medicine products,
            which are authorised in the UK. This data base can be found on their
            website here:
          </p>
          <p>http://www.vmd.defra.gov.uk/ProductInformationDatabase/</p>
          <p>&nbsp;</p>
          <p>
            To find out more about the petplanet team, please visit our Facebook
            Page
          </p>
          <br />
          <p>Petplanet.co.uk Limited</p>
          <p>Registered in Scotland No SC197870</p>
          <p>&nbsp;</p>
          <div className="aboutFinal">
            <p>Registered Office</p>
            <p>5 Kingsthorne Park</p>
            <p>Houstoun Industrial Estate</p>
            <p>Livingston</p>
            <p>West Lothian</p>
            <p>Scotland</p>
            <p>EH54 5DB</p>
            <p>Please note: our premises are not open to the public</p>
          </div>
        </div>
      </div>
      <Face />
      <Footer />
    </div>
  );
}

export default AboutUs;

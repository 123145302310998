import React from "react";
import "../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFolder } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <ul>
            <li>
              <Link to="/contact">
                <FontAwesomeIcon icon={faEnvelope} />
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/privacy">
                <FontAwesomeIcon icon={faFolder} /> Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/aboutUs">
                <FontAwesomeIcon icon={faFolder} /> About Us
              </Link>
            </li>
          </ul>
          <div className="botLRight">
            <div className="footerRow1">
              <spn className="footSpan">
                Shopping with us is safe and secure
              </spn>
            </div>
            <Link to="#">
              <img alt="" src="/faceIcon/icon4.png" />
            </Link>
            <Link to="#">
              <img alt="" src="/faceIcon/icon2.png" />
            </Link>
            <Link to="#">
              <img alt="" src="/faceIcon/icon3.png" />
            </Link>
          </div>
        </div>

        <div className="footerRow2">
          <img alt="" src="/faceIcon/icon5.png" className="footerIcon" />
          <div className="Footer1_signupPanel">
            <div className="signup">
              <div className="signup-header">
                <h2>Sign up for offers</h2>
              </div>
              <div className="signup-form">
                <div className="signup-content">
                  <p className="signup-text">
                    Sign up for our newsletter to receive exclusive offers &
                    discounts!
                  </p>
                  <a to="#" className="subscribe-button">
                    <FontAwesomeIcon icon={faEnvelope} /> SUBSCRIBE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="legal">
        <p className="legalLeft">
          <strong>Pet Planet</strong>, 5 Kingsthorne Park, Houstoun Ind Estate,
          Livingston, West Lothian EH54 5DB
        </p>
        <p className="legalRight">
          ©PetPlanet.co.uk Ltd 2024
          <br />
          All rights reserved
        </p>
      </div>
    </>
  );
}

export default Footer;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faClockRotateLeft,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import "../css/main.css";
import productData from "../Datas/productData";
import Face from "./Face";

function Main() {
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="firstTop">
          <div className="firstLeft">
            <FontAwesomeIcon icon={faTruck} />
            <span>
              <strong>Free</strong> UK delivery on most orders over £45*
            </span>
          </div>
          <div className="firstCenter">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <span>
              <strong>Next working / Nominated day</strong> delivery
            </span>
          </div>
          <div className="firstRight">
            <FontAwesomeIcon icon={faCheck} />
            <span>
              Collect points and <strong>earn rewards</strong> when you shop
            </span>
          </div>
        </div>
        <div className="twoDiv">
          <img src="/images/img2.jpg" alt="twoImg" />
        </div>
        <div className="threeTitleContainer">
          <span className="threeTitle">
            <h1>500+ Brands in Stock!</h1>
            <p>
              <Link to="/">Shop by Brands</Link>
            </p>
          </span>
        </div>
        <div className="fourDiv">
          <ul className="brand-icons">
            {productData
              .filter((brand) => brand.id >= 1 && brand.id <= 12)
              .map((brand, index) => (
                <li key={index}>
                  <Link to={`/shopDetails/${brand.id}`}>
                    <img alt="icon" src={brand.images[0]} />
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="fiveDiv">
          <ul className="brand-shops">
            {productData
              .filter((brand) => brand.id >= 13 && brand.id <= 18)
              .map((brand, index) => (
                <li key={index}>
                  <Link to={`/shopDetails/${brand.id}`}>
                    <img alt="icon" src={brand.images[0]} />
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className="sixDiv">
          <span className="threeTitle">
            <h1>Customer Favourites</h1>
          </span>
          <div className="sixShop">
            <ul className="shops">
              {productData
                .filter((brand) => brand.id >= 19 && brand.id <= 26)
                .map((brand, index) => (
                  <li key={index}>
                    <Link to={`/shopDetails/${brand.id}`}>
                      <img alt="icon" src={brand.images[0]} />
                    </Link>
                    <div className="sixName">
                      <h3>{brand.name}</h3>
                    </div>
                    <span className="price-container">
                      <p className="original-price">{brand.preprice}</p>
                      <p className="discounted-price">{brand.price[0]}</p>
                    </span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="finalDiv">
            <span className="threeTitle">
              {/* <h1>Latest Pet Product Reviews</h1> */}
            </span>
            <div className="finalBot">
              <div className="final">
                <img alt="" src="/images/final01.jpg" />
              </div>
              <div className="finalTxt">
                <h2>New Puppy or Kitten?</h2>
                <p>Discover everything you need to make them feel at home</p>
              </div>
              <div className="final">
                <img alt="" src="/images/final02.jpg" />
              </div>
            </div>
          </div>
          <div className="latest">
            <span className="threeTitle">
              <h1>Latest Pet Product Reviews</h1>
            </span>
            <div className="latestDiv">
              <ul className="lateDetail">
                {productData
                  .filter((brand) => brand.id >= 27 && brand.id <= 31)
                  .map((brand, index) => (
                    <li key={index}>
                      <Link to={`/shopDetails/${brand.id}`}>
                        <img alt="icon" src={brand.images[0]} />
                      </Link>
                      <h3>{brand.name}</h3>
                      <span className="detail-container">
                        <small className="late-mains">{brand.mains}</small>
                        <br />
                        <small className="late-mains">
                          {brand.description}
                        </small>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="footer-container">
            <span className="threeTitle">
              <h1>What our customers are saying:</h1>
            </span>
            <div className="footTop">
              <div className="footLeft">
                <h3 className="footH3">Fantastic service</h3>
                <small>Posted on 8/7/2024 by Karen</small>
                <p>
                  Really recommend this company as honest and trustworthy. Thank
                  you Pet Planet.
                </p>
              </div>
              <div className="footCenter">
                <h3 className="footH3">Customer service at its best</h3>
                <small>Posted on 28/6/2024 by Theresa</small>
                <p>
                  I contacted Pet Planet when Yodel failed to deliver my order.
                  Shona dealt with my query promptly, & efficiently. They
                  changed courier to DPD & a replacement order was sent via
                  express delivery & my order arrived lunchtime the following...
                </p>
              </div>
              <div className="footRight">
                <h3 className="footH3">Superb quality</h3>
                <small>Posted on 26/6/2024 by Jayden</small>
                <p>
                  Superb quality, value and service second to none. The website
                  is seamless and intuitive with excellent delivery options. I
                  was kept informed as to how my order was progressing and it
                  was delivered in perfect condition within a few days.
                </p>
              </div>
            </div>
          </div>
          <div>
            <span className="threeTitle">
              <h1>Our Partner Programmes</h1>
            </span>
            <div className="parent-div">
              <div className="child-div">
                <img alt="" src="/icons/icon14.png" />
              </div>
              <div className="child-div">
                <img alt="" src="/icons/icon13.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Face />
      <Footer />
    </div>
  );
}

export default Main;

const productData = [
  // icons
  {
    id: 1,
    name: "Performance Pima Box Tee",
    description:
      "Includes 2 Suit Jackets, 2 Suit Pants + 1 Shirt or Pants or Chinos or Polo of your choice for just $599.",
    price: "$55.30",
    preprice: "$99.90",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon1.jpg"],
    mains: "Suit",
    product: "13 products",
    brand: "BENJAMIN BARKER",
  },
  {
    id: 2,
    name: "Another Product",
    description: "This is another product description.",
    price: "$59.00",
    preprice: "$99.90",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon2.jpg"],
    mains: "Formal",
    product: "83 products",
    brand: "BENJAMIN BARKER",
  },
  {
    id: 3,
    name: "Another Product",
    description: "This is another product description.",
    price: "$69.00",
    preprice: "$99.90",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon3.jpg"],
    mains: "Casual",
    product: "20 products",
    brand: "BENJAMIN BARKER",
  },
  {
    id: 4,
    name: "Nouchi Cinder Suit Jacket",
    description:
      "Japanese fabrics; where tradition meets innovation. From superior raw materials to intricate designs, learn why these textiles are ideal for versatile summer suits.",
    price: "$225.00",
    preprice: "$450.00",
    size: ["46", "48", "50"],
    images: ["/icons/icon4.jpg"],
    mains: "Japanese Fabric - A Symphony of Fine Innovation",
    product: "13 products",
    first: "STORIES",
  },
  {
    id: 5,
    name: "Performance Pima Box Tee",
    description:
      "Includes 2 Suit Jackets, 2 Suit Pants + 1 Shirt or Pants or Chinos or Polo of your choice for just $599.",
    price: "$79.92",
    preprice: "$99.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon5.jpg"],
    mains: "Misura Embroidered White Linen",
    product: "13 products",
  },
  {
    id: 6,
    name: "Another Product",
    description: "This is another product description.",
    price: "$48.93",
    preprice: "$69.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon6.jpg"],
    mains: "BB Logo Polo Tee",
    product: "83 products",
  },
  {
    id: 7,
    name: "Another Product",
    description: "This is another product description.",
    price: "$89.90",
    preprice: "$109.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon7.png"],
    mains: "Wool Silk Tennis Polo",
    product: "20 products",
  },
  {
    id: 8,
    name: "Performance Pima Box Tee",
    description:
      "Japanese fabrics; where tradition meets innovation. From superior raw materials to intricate designs, learn why these textiles are ideal for versatile summer suits.",
    price: "$69.93",
    preprice: "$99.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon8.png"],
    mains: "Thoresby Blue Check",
    product: "13 products",
    first: "STORIES",
  },
  {
    id: 9,
    name: "Another Product",
    description:
      "The best things are those that achieve a delicate balance. This collection expertly navigates between styles and moods, offering flexibility for every occasion. It brings out the sweet spot where",
    price: "$69.93",
    preprice: "$69.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon9.png"],
    mains: "Langston Easycare Khaki",
    product: "83 products",
    first: "STORIES",
  },
  {
    id: 10,
    name: "Performance Pima Box Tee",
    description:
      "Includes 2 Suit Jackets, 2 Suit Pants + 1 Shirt or Pants or Chinos or Polo of your choice for just $599.",
    price: "$69.93",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon10.png"],
    mains: "Stripe Crew Pocket Tee",
    product: "13 products",
  },
  {
    id: 11,
    name: "Another Product",
    description: "This is another product description.",
    price: "$79.92",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon11.png"],
    mains: "Takimi Embroidered Blue Linen",
    product: "83 products",
  },
  {
    id: 12,
    name: "Another Product",
    description: "This is another product description.",
    price: "$55.93",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/icons/icon12.png"],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 13,
    name: "Orijen Original Grain Free Dry Cat Food",
    description: "This is another product description.",
    price: ["£29.99", "£57.99", "£113.98"],
    weight: ["1.8kg", "5.4kg", "10.8kg"],
    images: [
      "/shop/img1.jpg",
      "/detailImgs/first1.jpg",
      "/detailImgs/first12.jpg",
      "/detailImgs/first13.jpg",
      "/detailImgs/first14.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 14,
    name: "Acana Premium Adult Cat Wet Food - Lamb Pate",
    description: "This is another product description.",
    price: ["£1.49", "£8.94", "£17.88", "£33.79"],
    weight: ["1 x 85g", "6 x 85g", "12 x 85g", "24 x 85g"],
    images: ["/shop/img2.jpg", "/detailImgs/sec1.jpg", "/detailImgs/sec12.jpg"],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 15,
    name: "Ramsay Originals Adult Dry Dog Food - Chicken & Rice",
    description: "This is another product description.",
    price: ["£11.99", "£44.99", "£89.98"],
    weight: ["2.5kg", "12kg", "2x12kg"],
    images: [
      "/shop/img3.jpg",
      "/detailImgs/three1.jpg",
      "/detailImgs/three12.jpg",
      "/detailImgs/three13.jpg",
      "/detailImgs/three14.jpg",
      "/detailImgs/three15.jpg",
      "/detailImgs/three16.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 16,
    name: "PRO PLAN Veterinary Diets HA HypoAllergenic Dry Dog Food",
    description: "This is another product description.",
    price: ["£27.19", "£61.89"],
    weight: ["3kg", "11kg"],
    images: [
      "/shop/img4.jpg",
      "/detailImgs/four1.jpg",
      "/detailImgs/four12.jpg",
      "/detailImgs/four13.jpg",
      "/detailImgs/four14.jpg",
      "/detailImgs/four15.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 17,
    name: "Royal Canin Size Health Nutrition Giant Puppy Dry Dog Food - 15kg",
    description: "This is another product description.",
    price: ["£57.55"],
    weight: ["15kg"],
    images: [
      "/shop/img5.jpg",
      "/detailImgs/five1.jpg",
      "/detailImgs/five12.jpg",
      "/detailImgs/five13.jpg",
      "/detailImgs/five14.jpg",
      "/detailImgs/five15.jpg",
      "/detailImgs/five16.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 18,
    name: "Hills Prescription Diet c/d Multicare Wet Stew Dog Food - Chicken & Vegetables - 12x354g Cans",
    description: "This is another product description.",
    price: ["£38.39"],
    weight: ["12x354g Cans"],
    images: [
      "/shop/img6.jpg",
      "/detailImgs/six1.jpg",
      "/detailImgs/six12.jpg",
      "/detailImgs/six13.jpg",
      "/detailImgs/six14.jpg",
      "/detailImgs/six15.jpg",
      "/detailImgs/six16.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },

  {
    id: 19,
    name: "Orijen Original Grain Free Dry Dog Food - 11.4kg",
    description: "This is another product description.",
    price: ["£23.99", "£52.99", "£77.99"],
    preprice: "£77.99",
    weight: ["2kg", "6kg", "11.4kg"],
    images: [
      "/detailImgs/shop1.jpg",
      "/detailImgs/shop12.jpg",
      "/detailImgs/shop13.jpg",
      "/detailImgs/shop14.jpg",
      "/detailImgs/shop15.jpg",
      "/detailImgs/shop16.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 20,
    name: "Pedigree Dentastix Daily Dental Chew - Large Dog - 105 Stick Bulk Pack",
    description: "This is another product description.",
    price: ["£10.09", "£12.69", "£16.79"],
    preprice: "£44.87",
    weight: ["Small", "Medium", "Large"],
    images: [
      "/detailImgs/shop2.jpg",
      "/detailImgs/shop22.jpg",
      "/detailImgs/shop23.jpg",
      "/detailImgs/shop24.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 21,
    name: "Carnilove Adult Grain Free Dry Dog Food - Duck and Pheasant - 12kg",
    description: "This is another product description.",
    price: ["£50.34"],
    preprice: "£52.99",
    weight: ["12kg"],
    images: ["/shop/shop3.jpg"],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 22,
    name: "Wafcol Small & Medium Adult Dry Dog Food - Salmon & Potato - 12kg",
    description: "This is another product description.",
    price: ["£8.99  ", "£16.89", "£32.78"],
    preprice: "£48.99",
    weight: ["6kg", "12kg", "2x12kg"],
    images: [
      "/detailImgs/shop3.jpg",
      "/detailImgs/shop31.jpg",
      "/detailImgs/shop32.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 23,
    name: "Breeder Celect Recycled Paper Cat Litter - 30Ltr",
    description: "This is another product description.",
    price: ["£7.76", "£11.00", "13.99", "£20.98"],
    preprice: "£13.19",
    weight: ["10Lt", "20Lt", "30Lt", "2x20Lt"],
    images: ["/shop/shop5.jpg"],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 24,
    name: "Simpsons Premium Adult Dry Dog Food - Chicken & Brown Rice - 12kg",
    description: "This is another product description.",
    price: ["£12.97", "£52.99", "£105.98"],
    preprice: "£52.99",
    weight: ["2kg", "12kg", "24kg"],
    images: ["/shop/shop6.jpg"],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 25,
    name: "One Fast Cat Exercise Wheel - GEN 6 - Black ",
    description: "This is another product description.",
    price: ["£29.44"],
    preprice: "£30.99",
    weight: ["3.5kg"],
    images: [
      "/shop/shop8.jpg",
      "/detailImgs/shop4.jpg",
      "/detailImgs/shop41.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
  {
    id: 26,
    name: "Odourlock Clay Cat Litter - 12kg",
    description: "This is another product description.",
    price: ["£199.00", " £199.00"],
    preprice: "£249.99",
    weight: ["Black", "White"],
    images: [
      "/detailImgs/shop5.jpg",
      "/detailImgs/shop51.jpg",
      "/detailImgs/shop52.jpg",
      "/detailImgs/shop53.jpg",
    ],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },

  {
    id: 27,
    name: "Burns Original Adult Dry Dog Food - Chicken & Brown Rice",
    description:
      "Lovely stainless steel cat bowls easy to clean and keep water lovely and cool. Grace likes them and so do i. Would recommend",
    price: ["£51.99"],
    preprice: "£16.89",
    weight: ["12kg + 2kg"],
    images: [
      "/detailImgs/seven1.jpg",
      "/images/late01.jpg",
      "/detailImgs/seven12.jpg",
      "/detailImgs/seven13.jpg",
      "/detailImgs/seven14.jpg",
      "/detailImgs/seven15.jpg",
      "/detailImgs/seven16.jpg",
    ],
    mains: "Posted on 17/7/2024",
    product: "20 products",
  },
  {
    id: 28,
    name: "Go-Cat Adult Dry Cat Food - Herring, Tuna & Vegetables",
    description:
      "This activity centre is great for the price and our new rescue cat loves it and only leaves it for food, is on it most days (cat napping) The hammock part not been used yet but im sure it will. Easy to put together apart from one small...",
    price: ["£26.89"],
    preprice: "£16.89",
    weight: ["10kg"],
    images: [
      "/detailImgs/eight1.jpg",
      "/detailImgs/eight12.jpg",
      "/detailImgs/eight13.jpg",
      "/detailImgs/eight14.jpg",
      "/detailImgs/eight15.jpg",
      "/detailImgs/eight16.jpg",
      "/detailImgs/eight17.jpg",
    ],
    mains: "Posted on 17/7/2024",
    product: "20 products",
  },
  {
    id: 29,
    name: "Supreme Tiny Friends Danny Dog Doggy Donuts",
    description:
      "I purchased this pea husk litter as i wanted an all natural kind that would be kinder for the planet and good for the cat. I am absolutely amazed as to how long it lasts and how it works when wee'd upon, as long as this is available i...",
    price: ["£1.59"],
    preprice: "£16.89",
    weight: ["200g"],
    images: [
      "/detailImgs/nine13.jpg",
      "/detailImgs/nine1.jpg",
      "/detailImgs/nine12.jpg",
    ],
    mains: "Posted on 17/7/2024",
    product: "20 products",
  },
  {
    id: 30,
    name: "PRO PLAN Veterinary Diet Hydra Care Wet Cat Food - 10x85g Pouches",
    description:
      "My little cat has explored this bed but to my knowledge she doesn't sleep in it...she goes in it several times a day but just to collect her toys. We have moved it now upto the bedroom so when we go to bed so can she.",
    price: ["£6.89"],
    preprice: "£16.89",
    weight: ["10x85g"],
    images: [
      "/detailImgs/ten1.jpg",
      "/detailImgs/ten12.jpg",
      "/detailImgs/ten13.jpg",
      "/detailImgs/ten14.jpg",
      "/detailImgs/ten15.jpg",
      "/detailImgs/ten16.jpg",
      "/images/late04.jpg",
    ],
    mains: "Posted on 17/7/2024",
    product: "20 products",
  },
  {
    id: 31,
    name: "Hills Prescription Diet k/d Wet Cat Food in Gravy - Variety - 12x85g Pouches",
    description:
      "Grace sometimes plays with this but its not her favourite i think she's slightly wary of the bell",
    price: ["£14.19"],
    preprice: "£16.89",
    weight: ["12x85g"],
    images: [
      "/detailImgs/fin1.jpg",
      "/detailImgs/fin12.jpg",
      "/detailImgs/fin13.jpg",
      "/detailImgs/fin14.jpg",
      "/detailImgs/fin15.jpg",
      "/detailImgs/fin16.jpg",
      "/detailImgs/fin17.jpg",
      "/detailImgs/fin18.jpg",
      "/images/late05.jpg",
    ],
    mains: "Posted on 17/7/2024",
    product: "20 products",
  },
];

export default productData;

import "../css/header.css";
import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { Link } from "react-router-dom";

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    navigate("/cartpage");
  };
  const toMain = () => {
    navigate("/");
  };
  const { cartItems } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="left" onClick={toMain}>
            <img src="/images/img1.jpg" alt="brand" />
          </div>
          <div className="center">
            <Link to="/">Home</Link>
            <Link to="/aboutUs">About</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className="right" onClick={toggleSidebar}>
            <FontAwesomeIcon
              icon={faBasketShopping}
              size="2xl"
              color="#4f4d4d"
            />
            <span className="cart-count">{cartItems.length}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
